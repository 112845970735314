import {DialogContent, OutlinedInput, styled, Typography} from "@mui/material";
import { FlexBox } from "../../../../../styled/layout";

export const PopupContent = styled(DialogContent)`
  padding: 8rem;
  max-width: 65vw;
  position: relative;

  @media only screen and (max-width: 480px) {
    padding: 8rem 4rem;
    max-width: 80vw;
  }

  h4 {
    font-family: "Hellix", sans-serif;
    font-size: 2.52rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 1.21px;
    color: #0c1b26;
  }
`;

export const WalletInput = styled(OutlinedInput)`
  border-radius: 2.6rem;
  background-color: #fbf7f7;
  flex: 1;
  margin-right: 2.4rem;
  overflow: hidden;
  height: 7.4rem;
  font-size: 2.2rem;
  color: #0c1b26;
  box-shadow: 0 14px 13px 0 rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 4.5rem;
  }

  .MuiBox-root {
    background-color: #d8d8d8;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: -3rem;
    padding: 0 3rem 0 5rem;

    @media only screen and (max-width: 900px) {
      height: inherit;
    }

    @media only screen and (max-width: 600px) {
      padding: 0 1rem 0 2rem;
    }

    p {
      font-family: "Hellix", sans-serif;
      font-size: 2.2rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: 1.06px;
      color: #fff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    }
  }

  .MuiInputBase-input {
    margin-left: 2.3rem;
    font-family: "Hellix", sans-serif;
    font-size: 2.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: 1.06px;
    color: #0c1b26;
  }

  .MuiOutlinedInput-notchedOutline {
    border: solid 1px #d8d8d8 !important;
  }
`;

export const PaymentOptions = styled(FlexBox)`
  margin-top: 5rem;
  column-gap: 3rem;
  row-gap: 3rem;
  width: 100%;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    width: 90%;
    margin: 5rem auto 0;
  }
  img {
    width: 100%;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const ErrorMessageText = styled(Typography)`
  font-family: 'Hellix', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.72px;
  //text-align: center;
  color: #e01717;
  margin-left: 1rem;
`