import { PendingTable, TableSeparator } from "./PendingData.styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {ClockIcon, CoinsIcon, FlagIcon, GraphIcon} from "./img/PendingIcons";
import {format} from "date-fns";

const getData = (userData, paymentData, ethRate) => {
  // if(!userData) return []
  // console.log(paymentData)
  const date = new Date(paymentData?.time)
  return [
    {icon: <FlagIcon/>, title: 'Status', value: 'Pending'},
    {icon: <ClockIcon/>, title: 'Date & Time', value: format(date, 'dd/MM/yyyy - hh:mm a')},
    {icon: <CoinsIcon/>, title: 'Network', value: paymentData.network},
    // {icon: <GraphIcon/>, title: 'Exchange rate', value: `1 ETH = ${ethRate} USD`},
  ]
}


const PendingData = ({userData, paymentData, eth}) => {
  return (
    <PendingTable>
      <List>
        {getData(userData, paymentData, eth).map((el) => (
          <ListItem key={el.title}>
            <ListItemIcon>{el.icon}</ListItemIcon>
            <ListItemText>
              <Typography>{el.title}:</Typography>
              <TableSeparator /> <Typography>{el.value}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </PendingTable>
  );
};

export default PendingData;
