import {Box, styled} from "@mui/material";
import {theme} from "../../../../styled/theme";


export const PendingTable = styled(Box)`
  border-radius: 2rem;
  background-image: linear-gradient(213deg, rgba(238, 238, 238, 0.45) 120%, rgba(216, 216, 216, .45) -58%);
  padding: 7rem 4rem;
  
  ${theme.breakpoints.down('md')}{
    margin: 7rem 0;
    padding: 5rem 2rem;
  }
  
  .MuiListItemIcon-root{
    min-width: auto;
    margin-right: 2rem;
  }
  
  .MuiListItemText-root{
    > span{
      display: flex;
      
      p{
        &:first-of-type{
          font-size: 1.8rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.86px;
          color: #111e28;
          opacity: 1;
        }
        
        &:last-of-type{
          font-size: 1.6rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.77px;
          color: #817d90;
          opacity: 1;

        }
      }
    }
  }
  
  
`

export const TableSeparator = styled('span')`
  flex: 1;
  align-self: flex-end;
  height: 1px;
  margin-bottom: .5rem;
  //opacity: 0.35;
  background: repeating-linear-gradient(to right, transparent, transparent 3px, rgba(151, 151, 151, 0.65) 5px, rgba(151, 151, 151, 0.65) 10px);
  margin-right: 1rem;
`