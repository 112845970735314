// Core
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Style
import {
  CTAButton,
  CTAButtonOrange,
  FlexBox,
  FullPageContainer,
  SectionContainer,
} from "../../../styled/layout";
import { CopyrightText, MintHead } from "../mint/Mint.styled";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  GadgetsBgContainer,
  PlanetBg,
  SideBgContainer,
  WelcomeButtons,
} from "./Welcome.styles";

// Image
import { WebmLogoDark } from "../../../assets/img/icons";
import planetImg from "./img/Planet.webp";
import planetMob from "./img/Planet-m.webp";
import sideBg from "./img/Welcome-side-bg.webp";
import mobBg from "./img/Welcome-mob-bg.webp";
import { WelcomeBgGadgets } from "./img/Welcome.bg";
import { ButtonMask } from "../../../assets/img/masks";
import {
  DiscordTooltip,
  DiscordTooltipMob,
  WebmTooltip,
  WebmTooltipMob,
} from "./img/Welcome.icons";

// Utils
import {
  checkCoinbasePayment,
  checkPayment,
  checkStripePayment,
} from "../../../services/mintService";
import Skeleton from "../mint/skeleton/Skeleton";
import SkeletonPage from "../mint/skeleton/Skeleton";

const Welcome = ({ userData }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { linkId } = useParams();
  const [searchParams] = useSearchParams();
  // const sessionId = searchParams.get("session_id");
  // const coinbaseCode = searchParams.get("code");
  const paymentId = searchParams.get("payment_id");

  useEffect(() => {
    if (success || !userData) return;
    if (
      userData?.payment?.status?.toLowerCase() === "paid" ||
      userData?.payment?.status === "COMPLETED"
    ) {
      setSuccess(true);
      return;
    }

    const redirect = () => navigate(`/${linkId}?payment_id=${paymentId}`);

    const checkAllPayments = async () => {
      const res = await checkPayment(linkId, paymentId);
      // Stripe case
      if (res.method === "stripe" && res.status === "paid") {
        setSuccess(true);
        return;
      }

      // Coinbase case
      if (!res?.data?.length) {
        redirect();
      }
      const latestData = res?.data?.at(-1) || null;
      if (latestData?.status === "COMPLETED" || res.status === "paid") {
        setSuccess(true);
      } else {
        redirect();
      }
    };

    if (paymentId) {
      void checkAllPayments();
    } else {
      redirect();
    }
  }, [userData, paymentId]);

  if (!success)
    return (
      <SkeletonPage />
      // <FullPageContainer>
      //   <CircularProgress />
      // </FullPageContainer>
    );

  return (
    <Box
      position={"relative"}
      sx={{ overflowX: { xs: "visible", md: "hidden" } }}
    >
      <PlanetBg>
        <img src={isMobile ? planetMob : planetImg} alt="Planet background" />
      </PlanetBg>

      <SectionContainer pt={isMobile ? "10rem" : "26rem"}>
        <MintHead
          sx={
            isMobile
              ? { backgroundColor: "transparent", boxShadow: "none" }
              : {}
          }
        >
          <SideBgContainer className="desktopOnly">
            <img src={sideBg} alt="Background" />
          </SideBgContainer>
          <GadgetsBgContainer>
            <WelcomeBgGadgets className="desktopOnly" />
            <img src={mobBg} alt="Background" className="mobileOnly" />
          </GadgetsBgContainer>
          <WebmLogoDark style={{ marginTop: "4rem", marginBottom: "3.3rem" }} />
          <Typography variant={"h2"} sx={{ mb: "2.5rem !important" }}>
            Payment Successful!
          </Typography>
          <Typography
            maxWidth={"54rem"}
            sx={{ mb: { xs: "3rem", md: "15rem" } }}
          >
            You now have full access to the Webm marketing platform and
            community. As a Webm member, you can launch your first marketing
            campaign and meet other Webm members on Discord.
          </Typography>
          <WelcomeButtons>
            <ButtonMask />
            <ButtonMask />
            <ButtonMask />
            <a href="https://app.web3m.io" target="app">
              <CTAButtonOrange sx={{ width: "auto" }}>
                <span> Launch my first campaign</span>{" "}
                {isMobile ? <WebmTooltipMob /> : <WebmTooltip />}{" "}
              </CTAButtonOrange>
            </a>
            <a href="https://discord.gg/XxEngVn965" target="discord">
              <CTAButton>
                <span> Join the Discord community </span>{" "}
                {isMobile ? <DiscordTooltipMob /> : <DiscordTooltip />}{" "}
              </CTAButton>
            </a>
          </WelcomeButtons>
        </MintHead>
      </SectionContainer>
      <SectionContainer mt={"10rem !important"}>
        <FlexBox justifyContent={"space-between"} alignItems={"flex-end"}>
          <CopyrightText sx={isMobile ? { color: "#fff" } : {}}>
            All Rights Reserved &#169; Webm 2023
          </CopyrightText>
          <WebmLogoDark className="desktopOnly" />
        </FlexBox>
      </SectionContainer>
    </Box>
  );
};

export default Welcome;
