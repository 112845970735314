import { Box, Dialog, List, styled, Typography } from "@mui/material";

export const MintHead = styled(Box)`
  box-shadow: 0 2px 23px 0 rgba(206, 202, 207, 0.28);
  background-color: #fff;
  border-radius: 5rem;
  padding: 7rem 11rem 8rem 11.3rem;

  @media only screen and (max-width: 1200px) {
    padding: 5rem 5rem 5rem 7rem;
  }

  @media only screen and (max-width: 900px) {
    padding: 5rem 3rem 7rem 5rem;
  }

  @media only screen and (max-width: 480px) {
    padding-bottom: 10rem;
  }

  h2 {
    margin-top: 4rem;
    font-family: "F37 Judge", sans-serif;
    font-size: 4.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.21px;
    color: #1f1341;
    margin-bottom: 4.1rem;

    @media only screen and (max-width: 480px) {
      font-size: 4rem;
    }
  }

  p {
    opacity: 0.65;
    font-family: "Hellix", sans-serif;
    font-size: 1.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: 0.91px;
    color: #111e28;
    margin-right: 1rem;

    @media only screen and (max-width: 480px) {
      margin-bottom: 3rem;
    }
  }
`;

export const MintButtonContainer = styled(Box)`
  position: absolute;
  left: 0;
  top: calc(100% - 5.2rem);
  z-index: 1;

  @media only screen and (max-width: 1200px) {
    left: -2vw;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  > svg {
    position: absolute;
    width: 54rem;

    @media only screen and (max-width: 900px) {
      width: 100%;
    }

    @media only screen and (max-width: 480px) {
      width: 110%;
      transform: translateX(-4%) scaleY(0.75);
    }
    @media only screen and (max-width: 330px) {
      transform: translateX(-4%) scaleY(1);
    }

    &:first-of-type {
      transform: rotate(180deg);

      @media only screen and (max-width: 480px) {
        transform: rotate(180deg) translateX(4%) scaleY(0.75);
      }
      @media only screen and (max-width: 330px) {
        transform: rotate(180deg) translateX(4%) scaleY(1);
      }
    }

    &:last-of-type {
      top: calc(100% - 4.6rem);
      left: 0;

      @media only screen and (max-width: 600px) {
        top: calc(100% - 3.6rem);
      }

      @media only screen and (max-width: 480px) {
        top: calc(100% - 1rem);
      }
    }
  }

  > button {
    margin-left: 12rem;
    margin-top: 2rem;
    z-index: 1;
    padding: 3rem;

    @media only screen and (max-width: 900px) {
      display: block;
      margin: 0 auto;
      transform: translateY(25%);
    }

    @media only screen and (max-width: 600px) {
      transform: translateY(2.5vw);
      white-space: break-spaces;
      width: 50vw;
      padding: 3rem 2rem;
    }

    @media only screen and (max-width: 480px) {
      width: 58vw;
      font-size: 2.2rem;
      padding: 3rem 1rem;
      transform: translateY(6vw);
    }
  }
`;

export const MintBodyCard = styled(Box)`
  position: relative;
  border-radius: 5.6rem;
  background-color: #7f1190;
  background-size: cover;
  height: 64rem;

  @media only screen and (max-width: 900px) {
    height: auto;
  }

  > div > h3 {
    text-shadow: 0 6px 4px rgba(62, 4, 71, 0.66);
    font-family: "Hellix", sans-serif;
    font-size: 2.7rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 1.3px;
    color: #fff;
    margin-bottom: 3.8rem;
  }

  > div > h4 {
    text-shadow: 0 6px 4px rgba(62, 4, 71, 0.66);
    font-family: "Hellix", sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: 0.86px;
    color: #fff;
    margin-bottom: 1.2rem;
  }

  > div > p {
    text-shadow: 0 6px 4px rgba(62, 4, 71, 0.66);
    font-family: "Hellix", sans-serif;
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: 0.67px;
    color: #fff;
    margin-bottom: 4.75rem;
  }

  > .swiper {
    width: 95%;
    //max-height: 60rem;
    transform: scale(1.3) translate(-3rem, 0px);
    //transform-origin: bottom right;
    overflow: visible;
    img {
      width: 100%;
      transform: scale(1.05);
      transform-origin: top;
    }

    @media only screen and (max-width: 1024px) {
      transform: scale(1.35) translate(-3rem, 3vw);
    }

    @media only screen and (max-width: 940px) {
      transform: scale(1.35) translate(-3rem, 4.5vw);

      img {
        //transform: scale(1.1);
      }
    }

    @media only screen and (max-width: 900px) {
      transform: scale(1) translate(-1rem, -1rem);
    }
  }
`;

export const MintTimer = styled(Box)`
  position: relative;
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.2rem;
  margin-bottom: 4.2rem;

  > p {
    font-family: "Hellix", sans-serif;
    font-size: 4.1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: 1.97px;
    color: #725a76;
    margin-bottom: 0;
  }

  > svg {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }
`;

export const CopyrightText = styled(Typography)`
  margin-top: 3.8rem;
  opacity: 0.65;
  font-family: "Hellix", sans-serif;
  font-size: 1.52rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.73px;
  color: #111e28;
  padding-bottom: 5rem;

  @media only screen and (max-width: 900px) {
    text-align: center;
    width: 100%;
  }
`;

export const ImgMask = styled(Box)`
  width: 100%;
  position: absolute;
  height: 20rem;
  background-color: #f8f6f8;
  top: 100%;
  z-index: 10;

  @media only screen and (min-width: 901px) {
    margin-top: -1px;
    //display: none;
  }
`;

export const Popup = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 4.8rem;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.15);
    max-width: unset;

    > svg {
      position: absolute;
      top: 3.5rem;
      right: 3.5rem;
      cursor: pointer;
    }
  }
`;
