import { useEffect, useState } from "react";
import axios from "axios";

export const useCurrency = () => {
  const [ethPrice, setEthPrice] = useState(0);
  const [ethRate, setEthRate] = useState(0);

  useEffect(() => {
    const getCurrentRate = async () => {
      const res = await axios.get(
        "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json"
      );
      setEthRate(Math.round((1 / res.data?.usd?.eth) * 100) / 100)
      setEthPrice(Math.round(res.data?.usd?.eth * 500000) / 1000);
    };
    getCurrentRate();
  }, []);


  return { ethPrice, ethRate };
};
