import Countdown, { zeroPad } from "react-countdown";
import { Typography } from "@mui/material";
import {addHours, parse, parseISO} from "date-fns";

const Timer = ({ startTime }) => {
  // const startDate = new Date(startTime);
  const startDate = parseISO(startTime);

  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <Typography>
        {zeroPad(hours)} : {zeroPad(minutes)} : {zeroPad(seconds)}
      </Typography>
    );
  };

  return (
    <Countdown
      date={
        startTime
          ? addHours(startDate, 24 - startDate.getTimezoneOffset() / 60)
          : addHours(new Date(), 24)
      }
      onComplete={() => window.location.reload()}
      renderer={renderer}
    />
  );
};

export default Timer;
