export const ButtonMask = (props) => (
  <svg viewBox="0 0 539 72" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M146.904 71.96c-7.678 0-14.94-1.966-21.393-5.466-11.845-5.652-19.631-11.572-25.443-26.513l-.31-.812-.026-.077c-3.6-11.364-13.726-19.131-25.254-19.43l-.468-.009c-4.913-.04-9.959-.062-15.015-.068h-8.483c-18.024.026-35.317.185-46.32.185C-9.436 8.614 14.688 4.935 26.961 4.935l104.302-1.136a44.407 44.407 0 0 1 15.64-2.84l122.632 1.335L392.167.96c5.472 0 10.733.998 15.64 2.839L512.11 4.935c12.273 0 36.397 3.679 22.768 14.835l-6.122-.013c-11.02-.038-25.894-.156-41.218-.173l-5.295-.001-2.167.002c-5.056.006-10.102.027-15.016.068l-.468.008c-11.372.295-21.38 7.859-25.104 18.972l-.175.536-.026.073c-5.842 15.355-13.69 21.51-25.732 27.254-6.451 3.499-13.712 5.464-21.388 5.464H146.904Z"
      fill="#F8F6F8"
      fillRule="evenodd"
    />
  </svg>
);

export const RectMask = (props) => (
  <svg viewBox="0 0 295 83" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M.85.476h294v82H.85Z" fill="#F8F6F8" fillRule="evenodd" />
  </svg>
);
