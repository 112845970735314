// Style
import { PaymentOptions, PopupContent } from "../shared/MintPopup.styles";
import { Box, Typography } from "@mui/material";

// Images
import PayCreditImg from "./img/Pay-credit.webp";
import PayCryptoImg from "./img/Pay-crypto.webp";

// Utils
import { getCoinbaseLink, getStripeLink } from "../../../../../services/mintService";
import { useNavigate, useParams } from "react-router-dom";

const PaymentMethod = ({ userData, setPaymentLoading }) => {
  const { linkId } = useParams();
  const navigate = useNavigate();
  // console.log(params)
  const payWithStripe = async () => {
    setPaymentLoading('fullScreen');
    await getStripeLink(linkId, userData.user_id);
    setPaymentLoading('');

  };
  const payWithCoinbase = async () => {
    setPaymentLoading('fullScreen');
    await getCoinbaseLink(linkId, userData.user_id);
    setPaymentLoading('');
  };

  return (
    <PopupContent sx={{ width: "74rem" }}>
      <Typography variant="h4" maxWidth={"35rem"} ml={"3rem"}>
        Select payment method to complete your purchase:
      </Typography>
      <PaymentOptions>
        <Box onClick={payWithStripe}>
          <img src={PayCreditImg} alt={"Credit card payment"} />
        </Box>
          <Box onClick={payWithCoinbase}>
            <img src={PayCryptoImg} alt={"Crypto payment"} />
          </Box>
      </PaymentOptions>
    </PopupContent>
  );
};

export default PaymentMethod;
