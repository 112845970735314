import { Skeleton, styled, Typography } from "@mui/material";

export const RectSkeleton = (props) => {
  return (
    <Skeleton
      variant={"rectangular"}
      sx={{ borderRadius: "2.8rem", ...props.sx }}
      {...props}
    />
  );
};

export const TextSkeleton = (props) => {
  return (
    <Skeleton
      variant={"text"}
      sx={{ borderRadius: "2.5rem", ...props.sx }}
      {...props}
    />
  );
};

export const LoadingText = styled(Typography)`
  font-family: "Hellix", sans-serif;
  font-size: 2.7rem !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.3px !important;
  color: #b49999 !important;
  
  @media only screen and (max-width: 480px){
    display: none;
  }
  
  :after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 3000ms infinite;
    animation: ellipsis steps(4, end) 3000ms infinite;
    content: "\\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 2.5rem;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 2.5rem;
    }
  }

  //span {
  //  :nth-of-type(1) {
  //  }
  //  :nth-of-type(2) {
  //  }
  //  :nth-of-type(3) {
  //  }
  //}
`;
