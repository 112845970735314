import Welcome from "./welcome/Welcome";
import Mint from "./mint/Mint";
import { useEffect, useState } from "react";
import {Route, Routes, useParams, useSearchParams} from "react-router-dom";
import { checkLinkId } from "../../services/mintService";
import Page404 from "./404/Page404";

const Main = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const params = useParams();
  // const searchParams = useSearchParams();
  // const paymentId = searchParams.get("payment_id");

  useEffect(() => {
    if (!params.linkId) return;
    const controller = new AbortController();
    checkLinkId(params.linkId, controller.signal).then((res) => {
      if(!res.status){
        setUserData(res);
      }
      setLoading(false)
    }).catch(err => setLoading(false));
    // return () => {controller.abort();};
  }, []);

  // console.log(userData)

  if(!loading && !userData) return <Page404/>

  // return purchased ? <Welcome /> : <Mint purchase={purchase} userData={userData} />;
  return (
    <Routes>
      <Route path={"/success"} element={<Welcome userData={userData}/>} />
      <Route
        path={"/"}
        element={<Mint userData={userData} />}
      />
    </Routes>
  );
};

export default Main;
