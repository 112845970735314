import {List, styled} from "@mui/material";

export const MintSteps = styled(List)`
  .MuiListItem-root {
    margin-bottom: 1rem;
    //padding-right: 4rem;

    @media only screen and (max-width: 900px) {
      padding-left: 0;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 480px) {
      .MuiListItemIcon-root{
        max-width: 25%;
      }
    }
  }

  .MuiListItemText-primary {
    font-family: "Hellix", sans-serif;
    font-size: 2.2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 1.06px;
    color: #111e28;
    margin-left: 2.2rem;

    @media only screen and (max-width: 480px) {
      margin-left: 1.5rem;
    }
  }
`;
