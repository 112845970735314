import { Box, styled } from "@mui/material";
import { FlexBox } from "../../../styled/layout";

export const PlanetBg = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  width: 56vw;
  max-width: 104rem;
  //transform: translate(25%,-25%);

  @media only screen and (max-width: 900px){
    width: 100vw;
    top: calc(100% - 35rem);
    z-index: -1;
  }

  img {
    width: 100%;
  }
`;

export const SideBgContainer = styled(Box)`
  position: absolute;
  max-width: 47rem;
  top: 1.5rem;
  left: -8.5rem;
  z-index: -1;

  img {
    width: 100%;
  }
`;

export const GadgetsBgContainer = styled(Box)`
  position: absolute;
  top: 3rem;
  left: calc(100% - 28rem);
  transform: translate(-50%, 0);
  
  @media only screen and (max-width: 1200px){
    left: 85rem;
  }

  @media only screen and (max-width: 900px){
    position: absolute;
    left: unset;
    right: 0;
    transform: none;
    
    > img{
      display: block;
      margin-left: auto;
      width: 70%;
    }
  }

  svg {
    width: 97rem;
  }
`;

export const WelcomeButtons = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 75%);
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 900px){
    position: relative;
    margin: -10rem 0 10rem;
    //margin-left: 5rem;
    flex-direction: column;
    row-gap: 5rem;
  }
  
  button {
    position: relative;
    padding: 3.3rem;
    //z-index: 5;

    @media only screen and (max-width: 900px){
      width: fit-content;
    }

    @media only screen and (max-width: 480px){
      white-space: break-spaces;
      width: 100%;
      padding: 3rem 1rem !important;
      font-size: 2.2rem;
    }
    
    &:first-of-type {
      margin-right: 2.3rem;
      @media only screen and (max-width: 900px){
        padding-right: 5.1rem;
      }
    }

    > svg {
      position: absolute;
      left: -6%;
      bottom: 60%;

      @media only screen and (max-width: 900px){
        left: -4%;
        bottom: 70%;
        transform: scale(1.5);
      }
    }
  }

  > svg {
    position: absolute;
    top: -3.3rem;
    transform: rotate(180deg);
    width: 50%;

    @media only screen and (max-width: 900px){
      display: none;
    }

    &:first-of-type {
      left: 0;

      @media only screen and (max-width: 1024px){
        left: -4rem;
      }
    }
    
    &:nth-of-type(2){
      @media only screen and (max-width: 1024px){
        transform: rotate(180deg) scaleX(2);
      }
    }
    &:last-of-type {
      right: 0;

      @media only screen and (max-width: 1024px){
        right: -4rem;
      }
    }
  }
`;
