import "../src/assets/fonts/fonts.css";
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./styled/theme";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/mainContent/Main";
import Page404 from "./components/mainContent/404/Page404";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={"/:linkId/*"} element={<Main />} />
          <Route path={"*"} element={<Page404 />} />

          {/*<Route*/}
          {/*  path={"*"}*/}
          {/*  element={<Typography>Incorrect link!</Typography>}*/}
          {/*/>*/}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
