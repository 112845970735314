import {SectionContainer} from "../../../styled/layout";

const Page404 = (props) => {
 return (
     <SectionContainer sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      <svg
          width="533px"
          height="460px"
          viewBox="0 0 533 460"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
      >
       <defs>
        <linearGradient
            x1="48.7648147%"
            y1="44.7181565%"
            x2="50.1140737%"
            y2="55.8785523%"
            id="a"
        >
         <stop stopColor="#19C7DC" offset="24%" />
         <stop stopColor="#3B77D6" offset="100%" />
        </linearGradient>
       </defs>
       <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
            d="M349 2a18.94 18.94 0 0113.435 5.565A18.94 18.94 0 01368 21v109a18.94 18.94 0 01-5.565 13.435A18.94 18.94 0 01349 149H63.402l-23.274 27.495L37.57 149H21a18.94 18.94 0 01-13.435-5.565A18.94 18.94 0 012 130V21A18.94 18.94 0 017.565 7.565 18.94 18.94 0 0121 2z"
            stroke="#B5EBF4"
            strokeWidth={4}
            fill="#FFF"
            transform="translate(-429 -306) translate(429.72 306.953) rotate(-16 399.665 -211.078)"
        />
        <text
            fontFamily="Hellix-Medium, Hellix"
            fontSize={66}
            fontWeight={400}
            letterSpacing={1.26923077}
            transform="translate(-429 -306) translate(429.72 306.953) rotate(-16 399.665 -211.078)"
        >
         <tspan x={35.8138156} y={70.5415193} fill="#1B72C1">
          {"Oooops\u2026"}
         </tspan>
         <tspan
             x={42.1452834}
             y={105.920426}
             fontSize={24}
             letterSpacing={0.461538462}
             fill="#2492DD"
         >
          {"The URL you used is either"}
         </tspan>
         <tspan
             x={100.009015}
             y={136.920426}
             fontSize={24}
             letterSpacing={0.461538462}
             fill="#2492DD"
         >
          {"invalid or expired"}
         </tspan>
        </text>
        <text
            fontFamily="F37Judge-Bold, F37 Judge"
            fontSize={31}
            fontWeight="bold"
            letterSpacing={0.596153846}
            fill="#A0CDDB"
            transform="translate(-429 -306) translate(429.72 306.953)"
        >
         <tspan x={433.336741} y={422.882494}>
          {"404"}
         </tspan>
        </text>
        <g
            transform="translate(-429 -306) translate(429.72 306.953) translate(0 254.19)"
            fillRule="nonzero"
        >
         <g opacity={0.33}>
          <path
              d="M486.041 145.379c.13-4.592-.181-19.815-17.498-34.644-16.15-13.825-41.232-15.654-50.616-11.585 7.16-8.282 4.696-28.506-8.132-42.186-20.663-22.03-49.04-18.298-56.196-17.54-.29-2.416-1.539-8.77-6.853-15.767-6.093-8.024-18.931-18.395-45.477-22.245-29.62-4.293-49.098 2.016-60.222 8.065-9.821 5.34-14.964 11.295-16.663 13.523-5.611-3.258-33.607-17.82-64.144-3.875-13.757 6.28-21.276 14.786-25.352 22.705-26.026-9.774-56.236-4.956-74.49 11.445-15.204 13.66-20.092 33.545-13.083 51.883-26.571 5.46-36.867 14.661-41.378 21.985-9.65 15.67-4.633 41.926-4.633 41.927h511.122c.608 0 1.102-.409 1.102-.905 0-17.534-22.43-22.003-27.487-22.786z"
              fill="url(#a)"
              transform="translate(15.24 21.79)"
          />
          <path
              d="M31.555 96.879l-2.896-1.414 1.714-2.393c.456-.64.197-1.473-.577-1.85-.775-.376-1.782-.164-2.24.476l-1.708 2.393-2.896-1.413c-.775-.379-1.781-.165-2.24.476-.457.64-.197 1.473.576 1.85l2.896 1.413-1.71 2.393c-.456.64-.197 1.473.577 1.852.775.378 1.781.163 2.24-.476L27 97.793l2.896 1.413c.775.377 1.781.163 2.239-.477.458-.641.194-1.472-.581-1.85zM437.843 90.046l-2.172-1.06 1.284-1.795c.343-.481.148-1.105-.433-1.388-.581-.282-1.336-.123-1.68.362l-1.283 1.796-2.172-1.061c-.581-.284-1.336-.123-1.68.362-.344.485-.148 1.106.433 1.388l2.172 1.061-1.283 1.796c-.343.48-.149 1.104.432 1.388.581.284 1.338.123 1.68-.362l1.283-1.796 2.172 1.061c.581.282 1.338.121 1.68-.362.342-.483.148-1.106-.433-1.39zM226.4 13.653l-2.264-1.106 1.337-1.871c.362-.5.156-1.151-.45-1.448-.607-.297-1.394-.129-1.75.362l-1.338 1.871-2.265-1.104c-.606-.297-1.393-.128-1.752.371-.358.5-.154 1.153.45 1.448l2.265 1.104-1.337 1.872c-.362.501-.154 1.153.45 1.448.605.295 1.394.128 1.753-.373l1.337-1.872 2.264 1.106c.605.295 1.394.129 1.75-.372.357-.502.16-1.14-.45-1.436z"
              fill="#70CAF2"
              transform="translate(15.24 21.79)"
          />
         </g>
         <path
             d="M262.365 184.191c-151.59.746-251.505 5.206-262.365 8.017 13.659 2.188 110.624 10.67 262.21 11.743 137.092.972 269.35-12.978 269.35-12.978-16.387-4.228-117.604-7.53-269.195-6.782z"
             fill="#E2EDED"
         />
         <path
             d="M348.814 191.873c1.176.298 2.382.461 3.595.487 3.11.063 6.176-.753 8.844-2.353a16.513 16.513 0 007.964-13.72 16.538 16.538 0 00-2.353-8.844 15.995 15.995 0 00-2.206-2.878 16.363 16.363 0 00-7.92-4.592 15.907 15.907 0 00-3.594-.485 16.455 16.455 0 00-8.842 2.353 15.941 15.941 0 00-2.874 2.2 16.363 16.363 0 00-4.593 7.92 15.974 15.974 0 00-.483 3.59 16.471 16.471 0 002.353 8.845 15.86 15.86 0 002.208 2.878 16.341 16.341 0 007.92 4.592l-.019.007zm1.227-11.242a5.443 5.443 0 115.463-9.416 5.443 5.443 0 01-5.463 9.416z"
             fill="#2492DD"
         />
         <circle
             fill="#2492DD"
             transform="rotate(-5.94 365.245 183.18)"
             cx={365.244865}
             cy={183.180817}
             r={4.77488081}
         />
         <circle
             fill="#2492DD"
             transform="rotate(-47.2 356.477 189.855)"
             cx={356.477108}
             cy={189.85507}
             r={4.77488081}
         />
         <path
             d="M341.406 186.009a4.775 4.775 0 118.26 4.791 4.775 4.775 0 01-8.26-4.791zM367.94 176.84a4.777 4.777 0 11-2.455-9.232 4.777 4.777 0 012.456 9.233z"
             fill="#2492DD"
         />
         <circle
             fill="#2492DD"
             transform="rotate(-37.44 349.059 161.952)"
             cx={349.058533}
             cy={161.95212}
             r={4.77488081}
         />
         <path
             d="M364.14 165.84a4.775 4.775 0 11-8.26-4.792 4.775 4.775 0 018.26 4.791z"
             fill="#2492DD"
         />
         <circle
             fill="#2492DD"
             transform="rotate(-8.67 340.3 168.665)"
             cx={340.300481}
             cy={168.66453}
             r={4.77488081}
         />
         <path
             d="M337.605 175.016a4.777 4.777 0 112.456 9.232 4.777 4.777 0 01-2.456-9.232zM180.564 191.086c.762.194 1.543.3 2.33.315a10.719 10.719 0 005.734-1.524 10.34 10.34 0 001.864-1.432 10.572 10.572 0 002.976-5.132c.194-.762.3-1.544.315-2.33a10.7 10.7 0 00-1.524-5.733 10.696 10.696 0 00-8.893-5.157 10.667 10.667 0 00-10.886 10.417 10.663 10.663 0 001.52 5.734 10.652 10.652 0 006.564 4.842zm.796-7.287a3.526 3.526 0 114.82-1.282 3.53 3.53 0 01-4.814 1.282h-.006z"
             fill="#2492DD"
         />
         <path
             d="M189.667 188.115a3.095 3.095 0 113.108-5.353 3.095 3.095 0 01-3.108 5.353zM182.542 190.58a3.097 3.097 0 115.985-1.594 3.097 3.097 0 01-5.985 1.595z"
             fill="#2492DD"
         />
         <circle
             fill="#2492DD"
             transform="rotate(-54.395 177.459 188.858)"
             cx={177.458681}
             cy={188.85792}
             r={3.08353663}
         />
         <path
             d="M192.963 181.337a3.095 3.095 0 11-1.59-5.983 3.095 3.095 0 011.59 5.983zM183.719 170.916a3.095 3.095 0 11-5.983 1.588 3.095 3.095 0 015.983-1.588zM190.5 174.21a3.095 3.095 0 11-5.356-3.104 3.095 3.095 0 015.355 3.105zM176.595 173.38a3.095 3.095 0 11-3.107 5.354 3.095 3.095 0 013.107-5.354zM173.298 180.16a3.095 3.095 0 111.592 5.983 3.095 3.095 0 01-1.592-5.983z"
             fill="#2492DD"
         />
         <path
             d="M331.469 25.74a3.875 3.875 0 11-7.034-3.256 3.875 3.875 0 017.034 3.257z"
             fill="#FFF"
         />
         <path
             d="M325.69 28.539a4.978 4.978 0 116.783-2.331 4.983 4.983 0 01-6.62 2.41l-.164-.08zm3.527-6.89l-.092-.046a2.764 2.764 0 10-.218 5.1 2.733 2.733 0 001.557-1.428 2.762 2.762 0 00-1.247-3.62v-.005z"
             fill="#2492DD"
         />
         <path
             d="M317.895 38.527a1.106 1.106 0 01-.413-1.613l7.08-10.317a1.107 1.107 0 111.824 1.252l-7.077 10.317a1.106 1.106 0 01-1.414.36zM339.484 32.878a1.108 1.108 0 01-.022-1.96c1.415-.763 2.353-1.963 2.633-3.38.236-1.177-.02-2.37-.686-3.19a1.108 1.108 0 011.72-1.395c1.075 1.327 1.5 3.202 1.137 5.02-.415 2.074-1.749 3.813-3.754 4.886a1.11 1.11 0 01-1.028.019zM346.08 37.013a1.108 1.108 0 01-.02-1.962c2.48-1.33 4.12-3.439 4.622-5.946.424-2.112-.047-4.26-1.267-5.746a1.106 1.106 0 111.716-1.398c1.63 1.991 2.263 4.826 1.712 7.579-.633 3.164-2.675 5.814-5.746 7.463a1.111 1.111 0 01-1.018.01zM313.908 15.566a1.104 1.104 0 01-.603-1.033c.094-2.28 1.075-4.24 2.762-5.519 1.475-1.119 3.349-1.553 5.012-1.162a1.106 1.106 0 01-.507 2.154c-1.028-.24-2.212.047-3.167.77-1.152.875-1.821 2.242-1.89 3.85a1.106 1.106 0 01-1.607.94zM307.3 11.45c-.386-.197-.621-.6-.605-1.031.144-3.481 1.629-6.473 4.197-8.424 2.234-1.702 5.068-2.37 7.572-1.79a1.108 1.108 0 01-.502 2.157c-1.871-.434-4.014.087-5.729 1.392-2.029 1.546-3.209 3.944-3.325 6.755a1.108 1.108 0 01-1.609.941zM218.584 177.297s10.88 5.439 21.635 10.136c-6.415 2.087-20.582 5.865-28.575 1.586-7.573-4.055-12.592-16.834-14.842-23.748 10.388 6.112 21.782 12.026 21.782 12.026z"
             fill="#2492DD"
         />
         <path
             d="M225.53 165.007c13.706 6.816 34.25 16.057 49.683 20.113l-.096.385s-8.804 11.39-20.863 7.487c-3.871-1.251-8.94-3.322-14.028-5.552-10.755-4.706-21.635-10.136-21.635-10.136s-11.394-5.913-21.775-12.019c-4.489-2.644-8.786-5.328-11.888-7.578-10.26-7.461-6.228-21.28-6.228-21.28l.344-.41c12.444 10.272 32.555 21.751 46.337 28.915.047.03.098.054.15.075z"
             fill="#1B72C1"
         />
         <path
             d="M350.367 85.148a45.51 45.51 0 00-1.305-10.439 35.506 35.506 0 00-1.875-5.61c-8.932-20.73-33.366-34.795-47.305-42.053-1.682-.87-7.827-3.997-9.563-4.857-14.09-7.01-39.77-18.433-61.722-13.468-27.169 6.138-50.918 47.151-31.209 63.617h.011c.407.344.831.684 1.278 1 7.009 5.02 18.993 12.338 32.79 20.045l.951.522 14.042 7.638a711.741 711.741 0 009.646 4.985c22.108 11.193 44.257 20.743 56.428 24.797 1.319.437 2.678.74 4.058.906 6.516.803 12.574-1.745 17.7-6.375 9.58-9.972 14.944-26.727 16.075-40.708z"
             fill="#2492DD"
         />
         <path
             d="M320.793 146.07c1.75-9.318-4.292-13.088-4.292-13.088l.091-.762a20.8 20.8 0 01-4.058-.905c-12.17-4.048-34.32-13.597-56.428-24.798a672.578 672.578 0 01-9.646-4.985L232.41 93.91l-.95-.521c-13.798-7.707-25.783-15.023-32.791-20.046-.447-.315-.87-.656-1.278-1h-.01c-.307-.104-6.472-2.206-13.033 4.173-5.877 5.718-10.71 14.744-12.962 20.34-2.773 6.892-6.843 21.787-1.585 29.684 1.938 2.896 5.187 6.13 9.242 9.484 12.442 10.272 32.553 21.753 46.337 28.916.046.03.095.055.146.076 13.706 6.815 34.25 16.057 49.682 20.113 5.345 1.404 10.088 2.192 13.711 2.049 6.56-.273 12.377-4.525 17.21-10.317a53.172 53.172 0 005.847-8.412c3.556-6.474 7.542-15.595 8.818-22.379z"
             fill="#2492DD"
         />
         <path
             d="M178.198 128.433c-.59-1.991-.8-4.254-.758-6.607a30.552 30.552 0 011.37-9.895 68.304 68.304 0 013.099-9.727c2.286-5.575 7.104-14.609 12.806-20.416 3.584-3.65 6.969-4.646 9.293-4.803-1.991-1.31-3.801-2.535-5.342-3.642-.447-.315-.87-.656-1.278-1h-.01c-.306-.104-6.471-2.206-13.033 4.173-5.879 5.718-10.712 14.744-12.963 20.34-2.773 6.892-6.844 21.787-1.586 29.684 1.939 2.896 5.188 6.129 9.242 9.484.75.62 1.535 1.242 2.335 1.868a39.113 39.113 0 01-3.175-9.459z"
             fill="#70CAF2"
         />
         <path
             d="M249.288 102.52l-.969-.528c-14.04-7.81-26.245-15.204-33.4-20.25-.458-.32-.891-.66-1.31-1.003h-.008c-8.744-7.154-9.342-18.666-5.003-29.855 6.529-13.961 15.188-21.529 21.306-25.949 4.195-3.032 9.05-4.867 13.877-5.837 22.016-4.431 48.091 7.24 62.414 14.38 1.763.873 8.011 4.053 9.72 4.936 3.522 1.832 7.704 4.087 12.142 6.755-9.686-8.078-20.513-14.129-28.175-18.118-1.682-.867-7.827-3.997-9.563-4.855-14.09-7.012-39.77-18.433-61.733-13.47-27.169 6.14-50.916 47.153-31.207 63.62.41.343.833.684 1.28.999 7.007 5.019 18.99 12.337 32.789 20.046l.952.521 14.06 7.631a691.515 691.515 0 009.646 4.983 545.796 545.796 0 007.24 3.608l-14.058-7.613z"
             fill="#70CAF2"
         />
         <path
             d="M318.693 128.093a21.72 21.72 0 01-4.032-.96c-12.128-4.14-34.237-13.756-56.343-24.958a712.456 712.456 0 01-9.648-4.981l-14.06-7.603-.952-.519c-13.813-7.678-25.824-14.944-32.863-19.91a18.995 18.995 0 01-1.287-.985c-.578-.471-1.13-.972-1.656-1.5a37.117 37.117 0 01-7.666-9.581c.283 5.942 2.482 11.3 7.202 15.244.41.344.831.684 1.28 1 7.007 5.018 18.99 12.336 32.789 20.045l.952.522 14.051 7.636a691.6 691.6 0 009.646 4.985c22.108 11.193 44.257 20.743 56.428 24.797 1.319.436 2.677.74 4.056.906 6.516.801 12.564-1.753 17.69-6.38-3.901 1.606-10.576 2.948-15.587 2.242z"
             fill="#1B72C1"
         />
         <path
             d="M301.925 155.361a43.852 43.852 0 015.885 7.084c6 8.882 8.61 18.575 11.092 21.462.086.101.18.194.27.279.052.06.113.112.18.156.06.057.124.109.192.155h.024c.091.06.19.106.295.14 3.564 1.241 7.95-2.366 8.03-19.248.02-3.201-.213-6.399-.696-9.563-1.099-7.164-8.73-11.488-15.297-8.418-3.26 1.515-6.755 3.984-9.975 7.953z"
             fill="#2492DD"
         />
         <path
             d="M302.077 155.23l-.152.131a43.852 43.852 0 015.885 7.084c6 8.882 8.61 18.575 11.092 21.462.086.101.18.194.27.279.052.06.113.112.18.156.06.057.124.109.192.155h.024c3.229-.762 2.8-11.515-.656-19.405-2.834-6.471-11.07-14.817-16.835-9.861z"
             fill="#1B72C1"
         />
         <path
             d="M195.665 92.898a43.937 43.937 0 00-8.912-2.318c-10.59-1.662-20.474.088-24.143-.932a4.02 4.02 0 01-.362-.125.874.874 0 01-.215-.094 1.593 1.593 0 01-.223-.11v-.023a1.253 1.253 0 01-.251-.208c-2.638-2.699-1.233-8.201 14.018-15.443a60.939 60.939 0 018.953-3.428c6.952-2.047 14.107 3.026 14.118 10.274-.007 3.596-.758 7.808-2.983 12.407z"
             fill="#2492DD"
         />
         <path
             d="M195.716 92.707l-.053.191a43.937 43.937 0 00-8.912-2.318c-10.589-1.662-20.474.088-24.143-.932a4.02 4.02 0 01-.362-.125.874.874 0 01-.215-.094 1.593 1.593 0 01-.223-.11v-.023c-.68-3.245 9.232-7.42 17.85-7.645 7.044-.181 18.098 3.732 16.058 11.056z"
             fill="#1B72C1"
         />
         <path
             d="M252.76 13.394s-22.912.482-35.977 12.683c0-.002 9.33-21.405 35.976-12.683z"
             fill="#FFF"
         />
         <path
             d="M274.237 181.547s11.861 4.843 19.748.876c7.886-3.968 11.368-10.042 11.368-10.042s-6.823 5.05-14.033 7.059c-7.687 2.156-17.083 2.107-17.083 2.107z"
             fill="#70CAF2"
         />
         <path
             d="M177.156 130.453s-5.973-3.077-6.783-8.364c-.792-5.2 1.72-7.84 1.72-7.84a22.361 22.361 0 001.204 8.262c1.352 3.955 3.859 7.942 3.859 7.942z"
             fill="#FFF"
         />
         <path
             d="M249.95 188.687s6.65 4.192 12.192 2.56c5.452-1.606 6.406-5.146 6.406-5.146s-3.41 2.014-8.625 2.605c-4.613.523-9.973-.019-9.973-.019zM189.857 157.835s-7.134-2.825-8.984-8.22c-1.81-5.308.466-8.17.466-8.17s.333 3.92 2.829 8.435c2.206 3.993 5.689 7.955 5.689 7.955z"
             fill="#2492DD"
         />
         <path
             d="M224.385 188.367s-7.555 1.484-13.094-2.41c-5.45-3.831-5.922-7.762-5.922-7.762s3.258 3.406 8.612 6.123c4.733 2.406 10.404 4.049 10.404 4.049z"
             fill="#70CAF2"
         />
         <path
             d="M336.707 80.478c4.648 8.266.06 19.619-4.565 27.63-3.38 5.86-11.061 8.232-18.214 5.591-25.66-9.463-46.647-19.963-62.99-29.503-19.28-10.955-33.598-22.546-35.706-24.255-4.95-3.99-6.94-10.223-4.871-15.503 3.367-8.612 9.573-17.758 18.312-19.257 3.834-.659 11.8-.326 20.897.447 28.749 2.417 56.178 16.493 74.84 38.458 5.379 6.337 9.99 12.274 12.297 16.392z"
             fill="#ECFFFF"
         />
         <path
             d="M336.848 80.344c-2.306-4.129-6.925-10.082-12.308-16.432-18.669-22.022-46.13-36.115-74.936-38.506-9.111-.764-17.092-1.086-20.933-.424-8.757 1.515-14.985 10.701-18.37 19.348-1.448 3.701-.916 7.86 1.222 11.403-.86-2.534-.885-5.218.06-7.658 3.077-7.948 8.834-16.35 17.114-17.616 3.62-.555 11.202-.13 19.85.724 27.332 2.663 53.595 16.133 71.638 36.8 5.195 5.96 9.667 11.541 11.917 15.398 4.536 7.744.342 18.218-3.933 25.589-1.84 3.176-5.12 5.195-8.898 5.806 5.385.2 10.397-2.248 12.958-6.697 4.646-8.045 9.258-19.445 4.62-27.735z"
             fill="#1B72C1"
         />
         <path
             d="M280.181 87.35h-.085a1.66 1.66 0 01-1.57-1.744c.18-3.47-2.535-5.952-4.922-7.023-2.455-1.1-5.974-1.4-8.464 1.068a1.66 1.66 0 01-2.333-2.362c3.09-3.048 7.75-3.711 12.156-1.733 4.406 1.979 7.099 5.99 6.878 10.225a1.662 1.662 0 01-1.66 1.57zM232.48 56.23a1.66 1.66 0 01-.592-3.209l15.204-5.833a1.66 1.66 0 111.19 3.1l-15.205 5.832a1.63 1.63 0 01-.598.11z"
             fill="#256199"
         />
         <path
             d="M242.996 60.915a1.66 1.66 0 01-1.546-1.066l-5.834-15.204a1.662 1.662 0 013.1-1.191l5.833 15.204a1.663 1.663 0 01-1.553 2.255v.002zM303.1 87.68a1.66 1.66 0 01-.592-3.21l15.204-5.833a1.66 1.66 0 011.19 3.1l-15.205 5.833c-.19.074-.393.111-.597.11z"
             fill="#256199"
         />
         <path
             d="M313.616 92.364a1.663 1.663 0 01-1.547-1.066l-5.834-15.204a1.662 1.662 0 013.1-1.191l5.834 15.204a1.662 1.662 0 01-1.553 2.257z"
             fill="#256199"
         />
         <path
             d="M194.905 98.146s-12.453 12.99-8.362 29.594c4.09 16.603 35.686 26.67 35.686 26.67s-6.836-16.168 7.66-34.499c0 0-21.487-10.456-34.984-21.765z"
             fill="#1B72C1"
         />
         <path
             d="M202.817 125.378a9.821 9.821 0 002.202.389 10.19 10.19 0 005.508-1.231 10.116 10.116 0 004.851-6.055 9.821 9.821 0 00.39-2.202 10.183 10.183 0 00-1.231-5.506 9.789 9.789 0 00-1.29-1.829 10.06 10.06 0 00-4.765-3.024 9.785 9.785 0 00-2.203-.39 10.136 10.136 0 00-7.33 2.522 10.06 10.06 0 00-3.025 4.766 9.787 9.787 0 00-.387 2.199 10.15 10.15 0 001.225 5.508c.359.656.791 1.27 1.289 1.828a10.06 10.06 0 004.766 3.025zm1.035-6.897a3.356 3.356 0 113.594-5.668 3.356 3.356 0 01-3.594 5.668z"
             fill="#2492DD"
         />
         <path
             d="M212.975 108.512a2.076 2.076 0 11-3.505-2.226 2.076 2.076 0 013.505 2.226zM198.574 108.409a2.076 2.076 0 11-2.226 3.505 2.076 2.076 0 012.226-3.505zM205.801 105.476a2.076 2.076 0 11-4.052.907 2.076 2.076 0 014.052-.907zM195.54 115.587a2.076 2.076 0 11.907 4.052 2.076 2.076 0 01-.907-4.052zM205.65 125.843a2.076 2.076 0 114.051-.907 2.076 2.076 0 01-4.052.907zM198.47 122.81a2.076 2.076 0 113.508 2.221 2.076 2.076 0 01-3.507-2.222zM212.871 122.912a2.076 2.076 0 112.225-3.506 2.076 2.076 0 01-2.225 3.506zM215.905 115.732a2.076 2.076 0 11-.907-4.052 2.076 2.076 0 01.907 4.052zM200.972 141.726c.48.205.981.355 1.495.447a7.106 7.106 0 003.914-.413 6.853 6.853 0 001.37-.745 7.01 7.01 0 002.476-3.06c.206-.479.355-.98.445-1.494a7.09 7.09 0 00-.41-3.914 6.853 6.853 0 00-.746-1.37 7.119 7.119 0 00-4.555-2.923 7.075 7.075 0 00-5.281 1.16 6.985 6.985 0 00-1.448 1.352 6.91 6.91 0 00-1.02 1.707 7.072 7.072 0 00-.036 5.407c.196.483.446.943.746 1.37a7.01 7.01 0 003.06 2.476h-.01zm1.267-4.693a2.34 2.34 0 113.293-.346 2.344 2.344 0 01-3.291.333l-.002.013z"
             fill="#2492DD"
         />
         <path
             d="M209.36 130.846a1.448 1.448 0 11-2.252-1.82 1.448 1.448 0 012.252 1.82zM199.392 129.62a1.448 1.448 0 11-1.819 2.254 1.448 1.448 0 011.819-2.254zM204.63 128.169a1.448 1.448 0 11-2.88.306 1.448 1.448 0 012.88-.306zM196.715 134.352a1.448 1.448 0 11.306 2.88 1.448 1.448 0 01-.306-2.88zM202.905 142.269a1.448 1.448 0 112.88-.306 1.448 1.448 0 01-2.88.306zM198.167 139.59a1.448 1.448 0 112.253 1.819 1.448 1.448 0 01-2.253-1.819zM208.136 140.821a1.448 1.448 0 111.819-2.254 1.448 1.448 0 01-1.819 2.254zM210.813 136.084a1.448 1.448 0 11-.306-2.88 1.448 1.448 0 01.306 2.88z"
             fill="#2492DD"
         />
         <path
             d="M154.809 156.373a1.106 1.106 0 01-1.095-1.119c.108-10.295 5.108-19.639 13.716-25.637 7.2-5.018 15.704-6.697 22.2-4.371a1.106 1.106 0 01-.745 2.083c-5.829-2.083-13.576-.51-20.19 4.105-8.022 5.586-12.67 14.274-12.776 23.844a1.106 1.106 0 01-1.11 1.095z"
             fill="#1B72C1"
         />
         <path
             d="M165.48 163.497c1.686-.556 2.406-2.483 1.492-4.223-3.033-5.774-6.974-9.502-12.798-7.582-5.78 1.904-6.777 7.26-5.792 13.706.3 1.942 2.025 3.062 3.712 2.507l13.385-4.408z"
             fill="#1B72C1"
         />
         <path
             d="M156.585 173.358a1.195 1.195 0 01-1.51-.762l-2.37-7.19 2.27-.747 2.37 7.19a1.196 1.196 0 01-.76 1.51zM165.217 170.506a1.195 1.195 0 01-1.51-.76l-2.37-7.19 2.27-.739 2.374 7.186a1.195 1.195 0 01-.764 1.503z"
             fill="#1B72C1"
         />
        </g>
        <path
            d="M266.387 397.008a3.286 3.286 0 012.43 5.5 3.286 3.286 0 01-2.429 5.5h-6.634a1.08 1.08 0 110-2.16h6.633a1.125 1.125 0 100-2.251h-1.999a1.08 1.08 0 01-1.08-1.08v-.017c0-.597.484-1.08 1.08-1.08h2a1.125 1.125 0 000-2.25h-6.634a1.08 1.08 0 110-2.162h6.633zm-9.875 8.841c.59 0 1.067.48 1.067 1.07v.02c0 .59-.478 1.07-1.067 1.07h-5.938c-.59 0-1.068-.48-1.068-1.07v-.02c0-.59.478-1.07 1.068-1.07h5.938zm4.608-4.42c.589 0 1.066.479 1.066 1.07v.02c0 .59-.477 1.069-1.066 1.069h-10.548a1.068 1.068 0 01-1.066-1.07v-.02c0-.59.477-1.07 1.066-1.07h10.548zm-4.61-4.42c.59 0 1.068.478 1.068 1.069v.02c0 .59-.478 1.07-1.067 1.07h-5.937c-.59 0-1.068-.48-1.068-1.07v-.02c0-.591.478-1.07 1.068-1.07h5.937zm-9.18 0c.597 0 1.08.483 1.08 1.079v5.582a4.338 4.338 0 01-7.598 2.864 4.338 4.338 0 01-7.597-2.864v-5.582a1.08 1.08 0 112.159 0v5.582a2.18 2.18 0 104.359 0v-5.582a1.08 1.08 0 112.159 0v5.582a2.18 2.18 0 104.359 0v-5.582c0-.596.483-1.08 1.08-1.08zm37.56 11a1.08 1.08 0 001.08-1.08v-5.582a4.338 4.338 0 00-7.598-2.864 4.338 4.338 0 00-7.597 2.864v5.582a1.08 1.08 0 002.159 0v-5.582a2.18 2.18 0 014.359 0v5.582a1.08 1.08 0 002.158 0v-5.582a2.18 2.18 0 014.36 0v5.582c0 .596.483 1.08 1.079 1.08z"
            fill="#FFF"
            fillRule="nonzero"
            transform="translate(-429 -306) translate(429.72 306.953) rotate(24 259.592 402.508)"
        />
       </g>
      </svg>
     </SectionContainer>
 );}

export default Page404;