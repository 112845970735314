// Style
import {ErrorMessageText, PopupContent, WalletInput} from "../shared/MintPopup.styles";
import { Box, Typography } from "@mui/material";
import { CTAButton, FlexBox } from "../../../../../styled/layout";

// Hooks
import { useState } from "react";

// Images
import {RabbitArrowIcon, RabbitWithArrowIcon, WalletIcon} from "./EnterWallet.icons";

// Utils
import { updateWallet } from "../../../../../services/mintService";
import {AlertIcon} from "../../pendingData/img/PendingIcons";

const EnterWallet = ({ setPopup, userData }) => {
  const [input, setInput] = useState(userData.wallet_address || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const typeWallet = (e) => {
    setInput(e.target.value);
    if(error){
      setError(false)
    }
  };

  const handleButtonClick = async () => {
    // if (!input.length || !userData.email) return;
    if(input === userData.wallet_address){
      setInput("");
      setLoading(false);
      setPopup(2);
      return
    }

    setLoading(true);
    const res = await updateWallet(userData, input);
    if (res === "Incorrect wallet!") {
      setError(true);
      // alert("Incorrect wallet!");
    } else {
      setInput("");
      setPopup(2);
    }
    setLoading(false);
    // console.log(res.data.)
    // if (res.data.status !== "updated") return;
  };

  return (
    <PopupContent sx={{ width: "86rem" }}>
      <RabbitArrowIcon
        style={{ marginLeft: "6rem" }}
        className={"desktopOnly"}
      />
      <RabbitWithArrowIcon
        display={"block"}
        style={{ margin: "0 auto" }}
        className={"mobileOnly"}
      />
      <FlexBox alignItems={"center"} mb={"6rem"}>
        <WalletIcon style={{ flexShrink: 0, marginRight: '3rem' }} className={"desktopOnly"} />
        <Typography
          variant="h4"
          maxWidth={{ xs: "90%", md: "50rem" }}
          m={{xs: '0 auto', md: 'unset' }}
          // ml={{ xs: "0", md: "3.6rem" }}
        >
          Enter the full wallet ID for the wallet you want to attach your Webm
          NFT
        </Typography>
      </FlexBox>
      <FlexBox mb={"3rem"} flexDirection={{ xs: "column", md: "row" }}>
        <WalletInput
          startAdornment={
            <Box>
              <Typography>ETH</Typography>
            </Box>
          }
          placeholder={"Enter your wallet here…"}
          value={input}
          onChange={typeWallet}
          disabled={loading}
          sx={error ? { border: "solid 2px #e01717" } : {}}
        />
        <CTAButton
          sx={{ p: "2rem 6rem", maxWidth: {xs: '100%', sm: "30rem"}, margin: "0 auto", width: {xs: '100%', sm: '50%', md: 'unset'} }}
          onClick={handleButtonClick}
          loading={loading}
        >
          Next
        </CTAButton>
      </FlexBox>
      {error && (
        <FlexBox alignItems={"center"}>
          <AlertIcon height={"1.3rem"} width={"1.6rem"} />
          <ErrorMessageText>
            Incorrect wallet! Please use Ethereum wallet only.
          </ErrorMessageText>
        </FlexBox>
      )}
    </PopupContent>
  );
};

export default EnterWallet;
