import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { checkPayment, getCoinbaseLink } from "../../../../services/mintService";

const titleList = {
  default: "Mint your NFT to become a Webm insider",
  pending: "TRANSACTION IN PROGRESS",
  cancel: "Something went wrong",
};

const descList = {
  default:
    "You’re less than a minute away from joining Webm. Here’s what happens next:",
  pending:
    "Your purchase is being processed on the blockchain as we speak... You are just a few moments away from becoming a Web3m member! Once it will be approved, status will be changed.",
  cancel:
    "For some unknown reason, the transaction is failed. Please try again or choose another payment method.",
};

const checkUrl = {
  ethereum: "https://etherscan.io/tx",
  polygon: "https://polygonscan.com/tx",
  pusdc: "https://polygonscan.com/tx",
  pweth: "https://polygonscan.com/tx",
  usdc: "https://etherscan.io/tx",
  dai: "https://etherscan.io/tx",
  apecoin: "https://bscscan.com/tx",
  shibainu: "https://bscscan.com/tx",
  tether: "https://omniexplorer.info/tx",
  bitcoincash: "https://www.blockchain.com/bch/tx",
  dogecoin: "https://www.blockchain.com/doge/tx",
  litecoin: "https://www.blockchain.com/ltc/tx",
  bitcoin: "https://www.blockchain.com/btc/tx",
};

export const useMintPage = (userData) => {
  const [popup, setPopup] = useState(0);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState("");
  const [paymentData, setPaymentData] = useState({
    status: "DEFAULT",
    time: null,
    transactionId: "",
    network: "Ethereum",
  });

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("payment_id");

  const isDefaultPage = paymentData.status === "DEFAULT";
  const isPendingPage = paymentData.status === "PENDING";
  // const isCanceledPage = userData?.payment?.status === "CANCELED";
  const isCanceledPage = paymentData.status === "CANCELED";
  const userPaid =
    userData?.payment?.status === "COMPLETED" ||
    userData?.payment?.status === "paid";

  const title = isPendingPage
    ? titleList.pending
    : isCanceledPage
    ? titleList.cancel
    : titleList.default;
  const description = isPendingPage
    ? descList.pending
    : isCanceledPage
    ? descList.cancel
    : descList.default;
  const ctaButton = isPendingPage
    ? {
        text: "View payment",
        action: () =>
          window.open(
            `${checkUrl[paymentData.network]}/${paymentData.transactionId}`,
            paymentData.network
          ),
      }
    : { text: "Mint my Webm NFT", action: () => setPopup(1) };

  const checkPayments = async () => {
    const res = await checkPayment(params.linkId, paymentId);
    // console.log(res)
    const latestData = res?.data?.at(-1);
    if (!latestData) return setLoading(false);
    // console.log(latestData);
    setPaymentData({
      status: latestData.status,
      time: latestData.time,
      transactionId: latestData?.payment?.transaction_id,
      network: latestData?.payment?.network,
    });
    setLoading(false);
    // setStatus(res?.status);
  };

  useEffect(() => {
    if (!userData) return;
    if (!paymentId) return setLoading(false);

    void checkPayments();
  }, [userData, paymentId]);

  const payWithCoinbase = async () => {
    setPaymentLoading("buttons");
    await getCoinbaseLink(params.linkId, userData.user_id);
  };

  useEffect(() => {
    if (paymentData.status === "PENDING" && paymentId) {
      const checkForStatusUpdate = setInterval(async () => {
        const res = await checkPayment(params.linkId, paymentId);
        // const latestData = res?.data?.at(-1);
        // if (!latestData) return;
        if (res.status === "COMPLETED") {
          setPaymentData((prevState) => ({
            ...prevState,
            status: "COMPLETED",
          }));
        }
      }, 5000);
      return () => {
        clearInterval(checkForStatusUpdate);
      };
    }
  }, [paymentData.status]);

  useEffect(() => {
    if (!userData) return;
    if (
      userData?.payment?.status?.toLowerCase() === "paid" ||
      paymentData.status === "paid" ||
      paymentData.status === "COMPLETED"
    ) {
      navigate(
        `/${params?.linkId}/success?payment_id=${userData?.payment?.payment_id ?? paymentId}`
      );
      return;
    }
    const lastPayment = userData?.payment?.at(-1);
    if (lastPayment?.status === "paid") {
      navigate(
        `/${params?.linkId}/success?payment_id=${lastPayment.payment_id ?? paymentId}`
      );
    }
  }, [userData, paymentData.status]);

  useEffect(() => {
    if (paymentLoading !== "buttons") return;

    const updateLoaderState = setTimeout(() => {
      setPaymentLoading("fullScreen");
    }, 4000);

    return () => {
      clearTimeout(updateLoaderState);
    };
  }, [paymentLoading]);

  return {
    title,
    description,
    isDefaultPage,
    isPendingPage,
    isCanceledPage,
    userPaid,
    popup,
    setPopup,
    ctaButton,
    loading,
    // status,
    paymentData,
    payWithCoinbase,
    paymentLoading,
    setPaymentLoading,
  };
};
