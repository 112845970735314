export const WebmTooltip = (props) => (
    <svg
        width={'14.6rem'}
        height={'16.7rem'}
        viewBox={'0 0 146 167'}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <linearGradient x1="85.883%" y1="0%" x2="54.755%" y2="44.098%" id="wt-c">
                <stop stopColor="#F4CC47" offset="0%" />
                <stop stopColor="#E69D22" offset="100%" />
            </linearGradient>
            <filter
                x="-10.9%"
                y="-16%"
                width="138.5%"
                height="141.8%"
                filterUnits="objectBoundingBox"
                id="wt-a"
            >
                <feMorphology
                    radius={3}
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                />
                <feOffset
                    dx={10}
                    dy={2}
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={5}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feComposite
                    in="shadowBlurOuter1"
                    in2="SourceAlpha"
                    operator="out"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.224433525 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <path
                d="M59.775 130.992c3.366-11.716 7.122-11.76 10.544-12.369 27.985-4.98 49.232-29.433 49.232-58.848C119.55 26.762 92.788 0 59.775 0 26.762 0 0 26.762 0 59.775c0 29.668 21.614 54.288 49.954 58.973 3.196.528 6.985.485 9.821 12.244Z"
                id="wt-b"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(3 11.85)">
                <use fill="#000" filter="url(#wt-a)" xlinkHref="#wt-b" />
                <path
                    stroke="#F8F6F8"
                    strokeWidth={3}
                    d="M59.775-1.5c16.92 0 32.24 6.858 43.328 17.947 11.089 11.089 17.948 26.408 17.948 43.328 0 14.954-5.357 28.657-14.257 39.294-9.045 10.813-21.751 18.458-36.212 21.031a60.9 60.9 0 0 1-.532.092c-.228.038-.457.077-.687.12-1.522.303-3.03.814-4.48 2.709-1.272 1.66-2.482 4.264-3.666 8.386l-1.568 5.458-1.332-5.52c-.964-3.996-2.02-6.557-3.15-8.207-1.266-1.849-2.598-2.388-3.88-2.648-15.349-2.548-28.552-10.142-37.914-20.896C4.022 88.853-1.5 74.959-1.5 59.775c0-16.92 6.858-32.24 17.947-43.328C27.536 5.358 42.855-1.5 59.775-1.5Z"
                    fill="url(#wt-c)"
                />
            </g>
            <path
                d="M85.33 52.534c6.023 0 10.905 4.882 10.905 10.904 0 2.83-1.078 5.408-2.845 7.346a10.865 10.865 0 0 1 2.845 7.345c0 6.023-4.882 10.905-10.905 10.905H63.32a3.586 3.586 0 1 1 0-7.171h22.01a3.733 3.733 0 1 0 0-7.467h-6.632a3.586 3.586 0 0 1-3.586-3.586v-.053a3.586 3.586 0 0 1 3.586-3.585h6.632a3.733 3.733 0 0 0 0-7.467H63.32a3.586 3.586 0 1 1 0-7.171h22.01ZM52.562 81.87a3.545 3.545 0 0 1 3.542 3.549v.066a3.545 3.545 0 0 1-3.542 3.549H32.858a3.545 3.545 0 0 1-3.542-3.549v-.066a3.545 3.545 0 0 1 3.542-3.549h19.704Zm15.29-14.668a3.543 3.543 0 0 1 3.537 3.548v.067a3.543 3.543 0 0 1-3.537 3.549h-35a3.543 3.543 0 0 1-3.536-3.549v-.067a3.543 3.543 0 0 1 3.537-3.548h35ZM52.56 52.534a3.545 3.545 0 0 1 3.541 3.548v.067a3.545 3.545 0 0 1-3.541 3.548H32.858a3.545 3.545 0 0 1-3.542-3.548v-.067a3.545 3.545 0 0 1 3.542-3.548h19.7Z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
)

export const DiscordTooltip = (props) => (
    <svg
        width={'14.7rem'}
        height={'16.7rem'}
        viewBox={'0 0 147 167'}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <linearGradient x1="78.799%" y1="6.201%" x2="57.055%" y2="50%" id="wd-c">
                <stop stopColor="#3693FF" offset="0%" />
                <stop stopColor="#195AFE" offset="100%" />
            </linearGradient>
            <filter
                x="-10.9%"
                y="-16%"
                width="138.5%"
                height="141.8%"
                filterUnits="objectBoundingBox"
                id="wd-a"
            >
                <feMorphology
                    radius={3}
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                />
                <feOffset
                    dx={10}
                    dy={2}
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={5}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feComposite
                    in="shadowBlurOuter1"
                    in2="SourceAlpha"
                    operator="out"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.224433525 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <path
                d="M59.775 130.992c3.366-11.716 7.122-11.76 10.544-12.369 27.985-4.98 49.232-29.433 49.232-58.848C119.55 26.762 92.788 0 59.775 0 26.762 0 0 26.762 0 59.775c0 29.668 21.614 54.288 49.954 58.973 3.196.528 6.985.485 9.821 12.244Z"
                id="wd-b"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(3.5 11.85)">
                <use fill="#000" filter="url(#wd-a)" xlinkHref="#wd-b" />
                <path
                    stroke="#F8F6F8"
                    strokeWidth={3}
                    d="M59.775-1.5c16.92 0 32.24 6.858 43.328 17.947 11.089 11.089 17.948 26.408 17.948 43.328 0 14.954-5.357 28.657-14.257 39.294-9.045 10.813-21.751 18.458-36.212 21.031a60.9 60.9 0 0 1-.532.092c-.228.038-.457.077-.687.12-1.522.303-3.03.814-4.48 2.709-1.272 1.66-2.482 4.264-3.666 8.386l-1.568 5.458-1.332-5.52c-.964-3.996-2.02-6.557-3.15-8.207-1.266-1.849-2.598-2.388-3.88-2.648-15.349-2.548-28.552-10.142-37.914-20.896C4.022 88.853-1.5 74.959-1.5 59.775c0-16.92 6.858-32.24 17.947-43.328C27.536 5.358 42.855-1.5 59.775-1.5Z"
                    fill="url(#wd-c)"
                />
            </g>
            <path
                d="M73.474 68.267c-3.054 0-5.464 2.678-5.464 5.946s2.463 5.946 5.464 5.946c3.053 0 5.464-2.678 5.464-5.946s-2.41-5.946-5.464-5.946m-19.554 0c-3.053 0-5.464 2.678-5.464 5.946s2.464 5.946 5.464 5.946c3.054 0 5.465-2.678 5.465-5.946.053-3.268-2.41-5.946-5.465-5.946m24.91 25.017s-1.928-2.304-3.535-4.34c7.018-1.981 9.696-6.374 9.696-6.374-2.196 1.446-4.285 2.464-6.16 3.16-2.679 1.126-5.25 1.875-7.768 2.304-5.143.964-9.857.696-13.875-.054-3.053-.589-5.678-1.446-7.875-2.303a31.368 31.368 0 0 1-3.91-1.821c-.161-.107-.322-.161-.482-.268-.108-.054-.161-.107-.215-.161-.964-.536-1.5-.91-1.5-.91s2.572 4.285 9.375 6.32c-1.607 2.036-3.59 4.447-3.59 4.447-11.838-.375-16.338-8.143-16.338-8.143 0-17.25 7.714-31.231 7.714-31.231 7.714-5.786 15.053-5.626 15.053-5.626l.536.644c-9.643 2.785-14.089 7.017-14.089 7.017s1.179-.642 3.16-1.553c5.733-2.518 10.286-3.215 12.161-3.375.322-.054.59-.107.91-.107a45.327 45.327 0 0 1 10.822-.107 43.672 43.672 0 0 1 16.125 5.142s-4.232-4.018-13.339-6.803l.75-.858s7.339-.16 15.053 5.626c0 0 7.714 13.982 7.714 31.231 0 0-4.553 7.768-16.392 8.143"
                fill="#FFF"
            />
        </g>
    </svg>
)

export const WebmTooltipMob = (props) => (
    <svg
        width={46}
        height={45}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <linearGradient x1="93.08%" y1="0%" x2="55.709%" y2="44.098%" id="wm-c">
                <stop stopColor="#F4CC47" offset="0%" />
                <stop stopColor="#E69D22" offset="100%" />
            </linearGradient>
            <filter
                x="-19.9%"
                y="-32.1%"
                width="170.3%"
                height="170.3%"
                filterUnits="objectBoundingBox"
                id="wm-a"
            >
                <feMorphology
                    radius={1.5}
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                />
                <feOffset
                    dx={5}
                    dy={1}
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={2.5}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feComposite
                    in="shadowBlurOuter1"
                    in2="SourceAlpha"
                    operator="out"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.224433525 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <circle id="wm-b" cx={132.269} cy={281.75} r={16.363} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-114 -260)">
                <use fill="#000" filter="url(#wm-a)" xlinkHref="#wm-b" />
                <circle
                    stroke="#F8F6F8"
                    strokeWidth={1.5}
                    fill="url(#wm-c)"
                    cx={132.269}
                    cy={281.75}
                    r={17.113}
                />
            </g>
            <path
                d="M25.375 16a3.436 3.436 0 0 1 2.54 5.75 3.436 3.436 0 0 1-2.54 5.75h-6.934a1.13 1.13 0 0 1 0-2.26h6.934a1.176 1.176 0 1 0 0-2.352h-2.09a1.13 1.13 0 0 1-1.129-1.13v-.016c0-.624.506-1.13 1.13-1.13h2.09a1.176 1.176 0 0 0 0-2.353H18.44a1.13 1.13 0 0 1 0-2.259h6.934Zm-10.324 9.243c.616 0 1.116.5 1.116 1.118v.02c0 .618-.5 1.119-1.116 1.119H8.843c-.616 0-1.116-.5-1.116-1.118v-.021c0-.617.5-1.118 1.116-1.118h6.208Zm4.818-4.622c.615 0 1.114.501 1.114 1.119v.02c0 .618-.499 1.119-1.114 1.119H8.84c-.615 0-1.114-.501-1.114-1.119v-.02c0-.618.499-1.119 1.114-1.119H19.87ZM15.05 16c.617 0 1.116.5 1.116 1.118v.021c0 .617-.5 1.118-1.116 1.118H8.843c-.616 0-1.116-.5-1.116-1.118v-.02c0-.618.5-1.119 1.116-1.119h6.207Z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
)

export const DiscordTooltipMob = (props) => (
    <svg
        width={46}
        height={45}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <linearGradient x1="84.575%" y1="6.201%" x2="58.47%" y2="50%" id="dm-c">
                <stop stopColor="#3693FF" offset="0%" />
                <stop stopColor="#195AFE" offset="100%" />
            </linearGradient>
            <filter
                x="-19.9%"
                y="-32.1%"
                width="170.3%"
                height="170.3%"
                filterUnits="objectBoundingBox"
                id="dm-a"
            >
                <feMorphology
                    radius={1.5}
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                />
                <feOffset
                    dx={5}
                    dy={1}
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={2.5}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feComposite
                    in="shadowBlurOuter1"
                    in2="SourceAlpha"
                    operator="out"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.224433525 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <circle id="dm-b" cx={132.269} cy={356.632} r={16.363} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-114 -335)">
                <use fill="#000" filter="url(#dm-a)" xlinkHref="#dm-b" />
                <circle
                    stroke="#F8F6F8"
                    strokeWidth={1.5}
                    fill="url(#dm-c)"
                    cx={132.269}
                    cy={356.632}
                    r={17.113}
                />
            </g>
            <path
                d="M21.236 20.849c-.95 0-1.7.833-1.7 1.85 0 1.016.766 1.85 1.7 1.85.95 0 1.7-.834 1.7-1.85 0-1.017-.75-1.85-1.7-1.85m-6.084 0c-.95 0-1.7.833-1.7 1.85 0 1.016.767 1.85 1.7 1.85.95 0 1.7-.834 1.7-1.85.017-1.017-.75-1.85-1.7-1.85m7.75 7.783s-.6-.717-1.1-1.35c2.184-.617 3.017-1.984 3.017-1.984a9.533 9.533 0 0 1-1.917.984c-.833.35-1.633.583-2.416.716-1.6.3-3.067.217-4.317-.016-.95-.183-1.766-.45-2.45-.717-.383-.15-.8-.333-1.216-.566-.05-.034-.1-.05-.15-.084a.23.23 0 0 1-.067-.05 5.824 5.824 0 0 1-.467-.283s.8 1.333 2.917 1.966c-.5.634-1.117 1.384-1.117 1.384-3.683-.117-5.083-2.533-5.083-2.533 0-5.367 2.4-9.717 2.4-9.717 2.4-1.8 4.683-1.75 4.683-1.75l.167.2c-3 .867-4.383 2.183-4.383 2.183s.366-.2.983-.483c1.783-.783 3.2-1 3.783-1.05.1-.017.183-.033.283-.033a14.102 14.102 0 0 1 3.367-.034c1.583.184 3.283.65 5.017 1.6 0 0-1.317-1.25-4.15-2.116l.233-.267s2.283-.05 4.683 1.75c0 0 2.4 4.35 2.4 9.717 0 0-1.416 2.416-5.1 2.533"
                fill="#FFF"
            />
        </g>
    </svg>
)