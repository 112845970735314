import {styled, Typography} from "@mui/material";
import RabbitIcon from "./RabbitIcon";

const RabbitLoaderBackground = styled("div")`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.8);
  
  > p{
    font-family: 'Hellix', sans-serif;
    margin-top: 15rem;
    font-size: 3.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.43px;
    text-align: center;
    color: #fff;
  }
`;

const RabbitSpinnerContainer = styled("div")`
  //background-color: #ccc;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(4);

  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  div:nth-of-type(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  div:nth-of-type(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  div:nth-of-type(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  div:nth-of-type(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  div:nth-of-type(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  div:nth-of-type(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  div:nth-of-type(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  div:nth-of-type(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  div:nth-of-type(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  div:nth-of-type(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  div:nth-of-type(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  div:nth-of-type(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }

  > svg {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
      opacity: 0.4;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
`;

const RabbitLoader = () => {
  return (
    <RabbitLoaderBackground>
      <RabbitSpinnerContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <RabbitIcon />
      </RabbitSpinnerContainer>
      <Typography>Loading...</Typography>
    </RabbitLoaderBackground>
  );
};

export default RabbitLoader;
