export const WebmLogo = (props) => (
    <svg
        width={'19.5rem'}
        height={'5rem'}
        viewBox={'0 0 195 50'}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <filter
                x="-4.8%"
                y="-12.8%"
                width="109.6%"
                height="151.3%"
                filterUnits="objectBoundingBox"
                id="logo-a"
            >
                <feOffset dy={6} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    stdDeviation={2}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0.125490196 0 0 0 0 0.0784313725 0 0 0 0 0.274509804 0 0 0 0.546226235 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <path
                d="M578.884 1177.815c6.435 0 11.652 5.216 11.652 11.651a11.61 11.61 0 0 1-3.04 7.849 11.61 11.61 0 0 1 3.04 7.848c0 6.435-5.217 11.652-11.652 11.652h-23.517a3.831 3.831 0 1 1 0-7.663h23.517a3.99 3.99 0 0 0 0-7.978h-7.086a3.831 3.831 0 0 1-3.832-3.831v-.057a3.831 3.831 0 0 1 3.832-3.83h7.086a3.99 3.99 0 1 0 0-7.979h-23.517a3.831 3.831 0 1 1 0-7.662h23.517Zm-35.013 31.345a3.788 3.788 0 0 1 3.785 3.792v.071a3.788 3.788 0 0 1-3.785 3.792h-21.053a3.788 3.788 0 0 1-3.785-3.792v-.071a3.788 3.788 0 0 1 3.785-3.792h21.053Zm16.338-15.673a3.785 3.785 0 0 1 3.78 3.792v.071a3.785 3.785 0 0 1-3.78 3.792h-37.397a3.785 3.785 0 0 1-3.779-3.792v-.07a3.785 3.785 0 0 1 3.78-3.793h37.396Zm-16.34-15.672a3.788 3.788 0 0 1 3.783 3.791v.071a3.788 3.788 0 0 1-3.784 3.792h-21.05a3.788 3.788 0 0 1-3.785-3.792v-.07a3.788 3.788 0 0 1 3.785-3.792h21.05Zm-32.55 0a3.827 3.827 0 0 1 3.827 3.827v19.79c0 8.496-6.886 15.383-15.382 15.383a15.345 15.345 0 0 1-11.554-5.229 15.343 15.343 0 0 1-11.555 5.229c-8.495 0-15.381-6.887-15.381-15.382v-19.791a3.827 3.827 0 0 1 7.654 0v19.79a7.727 7.727 0 0 0 15.455 0v-19.79a3.827 3.827 0 0 1 7.654 0v19.79a7.727 7.727 0 0 0 15.455 0v-19.79a3.827 3.827 0 0 1 3.827-3.827Zm133.166 39a3.827 3.827 0 0 0 3.827-3.828v-19.79c0-8.496-6.887-15.382-15.382-15.382a15.345 15.345 0 0 0-11.555 5.228 15.343 15.343 0 0 0-11.554-5.228c-8.495 0-15.382 6.886-15.382 15.381v19.791a3.827 3.827 0 0 0 7.655 0v-19.79a7.727 7.727 0 0 1 15.454 0v19.79a3.827 3.827 0 0 0 7.655 0v-19.79a7.727 7.727 0 0 1 15.454 0v19.79a3.827 3.827 0 0 0 3.828 3.828Z"
                id="logo-b"
            />
        </defs>
        <g transform="translate(-457 -1177)" fillRule="nonzero" fill="none">
            <use fill="#000" filter="url(#logo-a)" xlinkHref="#logo-b" />
            <use fill="#FFF" xlinkHref="#logo-b" />
        </g>
    </svg>
)

export const WebmLogoDark = (props) => (
    <svg width={'11.1rem'} height={'2.4rem'} viewBox={'0 0 111 24'} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M69.822.56a6.871 6.871 0 0 1 5.078 11.5 6.871 6.871 0 0 1-5.079 11.5H55.954a2.26 2.26 0 1 1 0-4.518h13.869a2.353 2.353 0 1 0 0-4.705h-4.18a2.26 2.26 0 0 1-2.259-2.26v-.033a2.26 2.26 0 0 1 2.26-2.26h4.179a2.353 2.353 0 1 0 0-4.705h-13.87a2.26 2.26 0 1 1 0-4.518h13.87ZM49.173 19.048a2.234 2.234 0 0 1 2.232 2.236v.042c0 1.235-1 2.236-2.232 2.236H36.757a2.234 2.234 0 0 1-2.232-2.236v-.042c0-1.235 1-2.236 2.232-2.236h12.416Zm9.635-9.243a2.232 2.232 0 0 1 2.229 2.236v.042a2.232 2.232 0 0 1-2.229 2.236H36.754a2.232 2.232 0 0 1-2.229-2.236v-.042c0-1.235.998-2.236 2.229-2.236h22.054ZM49.171.56a2.234 2.234 0 0 1 2.232 2.236v.042c0 1.235-1 2.236-2.232 2.236H36.757a2.234 2.234 0 0 1-2.232-2.236v-.042c0-1.235 1-2.236 2.232-2.236h12.414Zm-19.195 0a2.257 2.257 0 0 1 2.257 2.257v11.671a9.071 9.071 0 0 1-15.886 5.988A9.071 9.071 0 0 1 .462 14.49V2.818a2.257 2.257 0 1 1 4.514 0v11.671a4.557 4.557 0 0 0 9.114 0V2.818a2.257 2.257 0 1 1 4.514 0v11.671a4.557 4.557 0 1 0 9.115 0V2.818A2.257 2.257 0 0 1 29.976.56Zm78.533 23a2.257 2.257 0 0 0 2.257-2.257V9.632a9.071 9.071 0 0 0-15.885-5.988 9.071 9.071 0 0 0-15.885 5.988v11.672a2.257 2.257 0 1 0 4.513 0V9.632a4.557 4.557 0 0 1 9.115 0v11.672a2.257 2.257 0 1 0 4.514 0V9.632a4.557 4.557 0 0 1 9.114 0v11.672a2.257 2.257 0 0 0 2.257 2.257Z"
            fill="#1F1341"
            fillRule="nonzero"
            opacity={0.36}
        />
    </svg>
)

export const CloseIcon = (props) => (
    <svg width={'2rem'} height={'2rem'} viewBox={'0 0 20 20'} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M1.183 1.072a1.898 1.898 0 0 1 2.686 0l6.257 6.257 6.256-6.257a1.898 1.898 0 0 1 2.687 0 1.898 1.898 0 0 1 0 2.687l-6.257 6.256 6.257 6.256a1.898 1.898 0 0 1 0 2.687 1.898 1.898 0 0 1-2.687 0L10.126 12.7l-6.257 6.257a1.898 1.898 0 0 1-2.686 0 1.898 1.898 0 0 1 0-2.687l6.256-6.256-6.256-6.256a1.898 1.898 0 0 1 0-2.687"
            fill="#293644"
            fillRule="evenodd"
            opacity={0.41}
        />
    </svg>
)
export const FireIcon = (props) => (
    <svg width={'4rem'} height={'4.9rem'} viewBox={'0 0 40 49'} xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="81.286%" id="fire-a">
                <stop stopColor="#E70078" offset="0%" />
                <stop stopColor="#CB0043" offset="100%" />
            </linearGradient>
        </defs>
        <path
            d="M287.774 6.329a2.085 2.085 0 0 0-3.314 1.352c-.947 6.153-3.725 9.688-6.559 13.294l-.063.082c-2.878 3.662-5.924 7.554-5.924 14.072 0 10.374 8.408 18.782 18.782 18.782s18.782-8.408 18.782-18.782c0-5.213-2.176-9.304-4.276-12.034a23.673 23.673 0 0 0-2.9-3.14 18.468 18.468 0 0 0-.977-.827c-.121-.096-.22-.169-.29-.223-.038-.025-.067-.048-.088-.063l-.03-.02-.008-.007-.006-.004a2.087 2.087 0 0 0-3.285 1.772v.05c0 .046 0 .127-.002.232a14.37 14.37 0 0 1-.054.943 18.107 18.107 0 0 1-.589 3.216c-.64 2.283-1.867 4.854-4.205 6.77 1.108-3.164 2.2-7.496 1.975-11.95-.245-4.798-2.031-9.813-6.969-13.515"
            transform="rotate(27 167.958 -531.273)"
            fill="url(#fire-a)"
            stroke="#FFF"
            strokeWidth={2}
            fillRule="evenodd"
        />
    </svg>
)