// Core
import { useEffect, useState } from "react";

// Components
import EnterWallet from "./popups/enterWallet/EnterWallet";
import PaymentMethod from "./popups/paymentMethod/PaymentMethod";

// Style
import {
  CTAButton,
  CTAButtonOrange,
  FlexBox,
  FullPageContainer,
  SectionContainer,
} from "../../../styled/layout";
import {
  CircularProgress,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CopyrightText,
  ImgMask,
  MintBodyCard,
  MintButtonContainer,
  MintHead,
  MintSteps,
  MintTimer,
  Popup,
} from "./Mint.styled";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

// Images
import { ButtonMask } from "../../../assets/img/masks";
import { CloseIcon, FireIcon, WebmLogo } from "../../../assets/img/icons";
import bg1 from "./img/bg/Mint-card-1.webp";
import bg1m from "./img/bg/Mint-card-1-m.webp";
import bg2 from "./img/bg/Mint-card-2.webp";
import bg2m from "./img/bg/Mint-card-2-m.webp";

// Utils
import Timer from "./timer/Timer";
import { useCurrency } from "./data/useCurrency";
import { useParams } from "react-router-dom";
import { rabImages } from "./data/Mint.data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import { useMintPage } from "./data/useMintPage";
import Steps from "./steps/Steps";
import PendingData from "./pendingData/PendingData";
import { AlertIcon } from "./pendingData/img/PendingIcons";
import SkeletonPage from "./skeleton/Skeleton";
import RabbitLoader from "./loader/RabbitLoader";

const Mint = ({ userData }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { ethPrice, ethRate } = useCurrency();

  const {
    title,
    description,
    isDefaultPage,
    isPendingPage,
    isCanceledPage,
    popup,
    loading,
    setPopup,
    ctaButton,
    payWithCoinbase,
    paymentData,
    paymentLoading,
    setPaymentLoading,
  } = useMintPage(userData);
  // console.log(paymentData)

  if (!userData || loading) return <SkeletonPage />;

  return (
    <>
      {paymentLoading === "fullScreen" && <RabbitLoader />}
      <SectionContainer pt="10rem">
        {/*<CoinbaseCommerceButton checkoutId={'My checkout ID'}/>*/}
        <MintHead sx={isCanceledPage ? { border: "solid 2px #e41f1f" } : {}}>
          <Grid container>
            <Grid item xs={12} md={5.2}>
              <Stack mr={{ xs: "1rem", sm: "4.5rem" }}>
                <FlexBox alignItems={"center"}>
                  {isCanceledPage && <AlertIcon />}
                  <Typography
                    variant={"h2"}
                    sx={
                      isCanceledPage
                        ? { color: "#e41f1f !important", ml: "2rem" }
                        : {}
                    }
                  >
                    {title}
                  </Typography>
                </FlexBox>
                <Typography>{description}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6.8}>
              {isDefaultPage && <Steps />}
              {isPendingPage && (
                <PendingData
                  userData={userData}
                  paymentData={paymentData}
                  eth={ethRate}
                />
              )}
              {isCanceledPage && (
                <Stack
                  rowGap={"3rem"}
                  height={"100%"}
                  justifyContent={"center"}
                  m={{ xs: "5rem auto", md: "unset" }}
                >
                  <CTAButtonOrange
                    sx={{ width: { xs: "100%", sm: "80%" }, m: "0 auto" }}
                    onClick={payWithCoinbase}
                    disabled={paymentLoading === "buttons"}
                  >
                    {paymentLoading === "buttons"
                      ? "Loading..."
                      : "Try again with crypto"}
                  </CTAButtonOrange>
                  <CTAButton
                    sx={{ width: { xs: "100%", sm: "80%" }, m: "0 auto" }}
                    onClick={() => setPopup(2)}
                    disabled={paymentLoading === "buttons"}
                  >
                    {paymentLoading === "buttons"
                      ? "Loading..."
                      : "Change payment method"}
                  </CTAButton>
                </Stack>
              )}
            </Grid>
          </Grid>
        </MintHead>
        {!isCanceledPage && (
          <MintButtonContainer>
            <ButtonMask />
            <CTAButtonOrange onClick={ctaButton.action}>
              {ctaButton.text}
            </CTAButtonOrange>
            <ButtonMask />
          </MintButtonContainer>
        )}
      </SectionContainer>
      <SectionContainer>
        <Grid container spacing={4.5} mt={"0rem"}>
          <Grid item xs={12} md={5.7}>
            <MintBodyCard
              sx={{
                backgroundImage: `url(${isMobile ? bg1m : bg1})`,
                // display: !isDefaultPage && isMobile ? "none" : "block",
                minHeight: isDefaultPage ? "unset" : "60rem",
              }}
            >
              {isDefaultPage && (
                <Stack maxWidth={"30rem"} m={"0 auto"} p={"12rem 0 7.6rem"}>
                  <Typography variant={"h3"}>
                    Mint your Webm NFT for $500 ({ethPrice} ETH)
                  </Typography>
                  <MintTimer>
                    <FireIcon />
                    <Timer startTime={userData?.date} />
                  </MintTimer>
                  <Typography variant={"h4"}>What’s with the timer?</Typography>
                  <Typography>
                    Web3m is an exclusive tool and community. Only 2,000 NFTs
                    are available to be minted. You have 24 hours to mint your
                    NFT before we pass the opportunity to the next person.
                  </Typography>
                  <WebmLogo />
                </Stack>
              )}
              {!isDefaultPage && (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"100%"}
                  minHeight={"60rem"}
                >
                  <WebmLogo transform={"scale(1.1)"} />{" "}
                  <Typography>https://web3m.io</Typography>
                </Stack>
              )}
            </MintBodyCard>
          </Grid>
          <Grid item xs={12} md={6.3}>
            <MintBodyCard
              sx={{
                backgroundImage: `url(${isMobile ? bg2m : bg2})`,
                mt: isMobile ? "5rem" : 0,
                display: !isMobile ? "block" : "none",
              }}
            >
              <Swiper
                modules={[Autoplay, EffectFade]}
                speed={1500}
                noSwiping
                noSwipingClass={"swiper-slide"}
                autoplay={{ delay: 3000 }}
                effect="fade"
                fadeEffect={{ crossFade: true }}
                className="mySwiper"
                slidesPerView={1}
                loop
                // preventInteractionOnTransition
              >
                {rabImages.map((el, i) => (
                  <SwiperSlide key={i}>
                    <img src={el} alt={"nft rabbit"} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/*<img src={CyberHacker} alt={"NFT Rabbit"} />{" "}*/}
              <ImgMask />
            </MintBodyCard>
          </Grid>
        </Grid>
        <CopyrightText>All Rights Reserved &#169; Webm 2023</CopyrightText>
        <Popup
          open={!!popup}
          onClose={() => setPopup(0)}
          sx={{
            [".MuiPaper-root"]: { m: "0", maxHeight: { xs: "98%", md: "90%" } },
          }}
        >
          {popup === 1 && (
            <EnterWallet setPopup={setPopup} userData={userData} />
          )}
          {popup === 2 && (
            <PaymentMethod
              setPopup={setPopup}
              // purchase={purchase}
              userData={userData}
              setPaymentLoading={setPaymentLoading}
            />
          )}
          <CloseIcon onClick={() => setPopup(0)} />
        </Popup>
      </SectionContainer>
    </>
  );
};

export default Mint;
