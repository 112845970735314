import { Box, Button, Container, styled } from "@mui/material";
import {LoadingButton} from "@mui/lab";

export const SectionContainer = styled(Box)`
  position: relative;
  width: 90%;
  margin: 0 auto;
  max-width: 114rem;
`;

export const FullPageContainer = styled(SectionContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const FlexBox = styled(Box)`
  display: flex;
`;

export const CTAButton = styled(LoadingButton)`
  border-radius: 2.8rem;
  padding: 3rem 5.75rem 3.9rem;
  background-color: #195afe;

  text-shadow: 0 4px 4px #1a50d6;
  font-family: Hellix;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: 1.2px;
  color: #fff;
  text-transform: none;
  white-space: nowrap;
  
  &:disabled{
    color: #fff;
    background-color: #ccc;
    text-shadow: none !important;
  }
  
  &:hover{
    background-color: #195afe;
  }
`;

export const CTAButtonOrange = styled(CTAButton)`
  background-color: #e69d22;
  text-shadow: 0 4px 4px rgba(172, 117, 25, 0.48);
  width: 30rem;

  &:hover{
    background-color: #e69d22;
  }
`;
