import axios from "axios";
import Web3 from "web3";
import { isDEV } from "../utils/constants";

const apiClient = axios.create({
  baseURL: `https://minter-page${
    isDEV ? "-develop" : ""
  }-dot-winky-apis.ew.r.appspot.com/`,
  // baseURL: "https://mvp-web3-develop-dot-winky-apis.ew.r.appspot.com/",
});

export const checkLinkId = async (linkId, signal) => {
  try {
    const linkRes = await apiClient.get(`/check/${linkId}`, { signal });

    const userRes = await apiClient.get(`/user/${linkRes.data.email}`);

    return { ...userRes?.data, date: linkRes.data.date };
  } catch (e) {
    return null;
  }
};

export const updateWallet = async (userData, walletAddress) => {
  const walletValid = Web3.utils.isAddress(walletAddress);

  if (walletValid) {
    const res = await apiClient.patch(`/user/${userData.user_id}`, {
      wallet_address: walletAddress,
    });

    return res;
  } else {
    return "Incorrect wallet!";
  }
};

export const getStripeLink = async (linkId, userId) => {
  const data = {
    link_mint_id: linkId,
    user_id: userId,
  };
  const res = await apiClient.post("/stripe", data);
  if (res.data.link) {
    window.open(res.data.link, "_self");
  }
};

export const checkStripePayment = async (sessionId) => {
  const res = await apiClient.get(`stripe/${sessionId}`);
  return res.data;
};

export const getCoinbaseLink = async (linkId, userId) => {
  const data = {
    link_mint_id: linkId,
    user_id: userId,
  };
  const res = await apiClient.post("/coinbase", data);
  if (res.data?.link) {
    window.open(res.data.link, "_self");
  }
};

export const checkCoinbasePayment = async (code) => {
  // const res = await apiClient.get(`coinbase/${sessionId}`)

  const res = await axios.get(
    `https://api.commerce.coinbase.com/charges/${code}`,
    { headers: { "X-CC-Version": "2018-03-22" } }
  );

  // console.log(res.data?.data?.payments);

  return res.data?.data?.payments?.length;
};


export const checkPayment = async (linkId, paymentId) => {
  // if(!paymentId) return;

  const data = {
    link_mint_id: linkId,
    payment_id: paymentId
  }
  const res = await apiClient.post(`/check_payment`, data);
  return res.data;
}
