import { MintSteps } from "./Steps.styles";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Step1Icon, Step2Icon, Step3Icon } from "../img/MintStepIcons";

const Steps = () => {
  return (
    <MintSteps>
      <ListItem>
        <ListItemIcon>
          <Step1Icon />
        </ListItemIcon>
        <ListItemText>Click “Mint My Webm NFT”</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Step2Icon />
        </ListItemIcon>
        <ListItemText>Pass simple process to purchase NFT</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Step3Icon />
        </ListItemIcon>
        <ListItemText>Enjoy the Webm platform and community</ListItemText>
      </ListItem>
    </MintSteps>
  );
};

export default Steps;
