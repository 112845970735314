import {FlexBox, SectionContainer} from "../../../../styled/layout";
import { MintBodyCard, MintHead } from "../Mint.styled";
import {Grid, Skeleton, Typography} from "@mui/material";
import { WebmLogoDark } from "../../../../assets/img/icons";
import {LoadingText, RectSkeleton, TextSkeleton} from "./Skeleton.styles";
import {useState} from "react";

const SkeletonPage = () => {
  const [dots, setDots] = useState(1);

  return (
    <>
      <SectionContainer pt={"10rem"}>
        <MintHead  sx={{backgroundColor: '#f2e8e8', p:'5rem'}}>
          <FlexBox justifyContent={'space-between'}>
            <WebmLogoDark transform='scale(2)' style={{transformOrigin: 'top left'}}  />
            <LoadingText>Loading</LoadingText>
          </FlexBox>
          <Grid container spacing={"4rem"} mt={"3rem"}>
            <Grid item xs={12} md={4}>
              <RectSkeleton height={"33rem"} />
            </Grid>{" "}
            <Grid item xs={12} md={8} mt={'-1.5rem'}>
              <TextSkeleton height={"6.5rem"} width={"100%"} />
              <TextSkeleton height={"6.5rem"} width={"100%"} />
              <TextSkeleton height={"6.5rem"} width={"60%"} />
              <TextSkeleton height={"6.5rem"} width={"60%"} />
              <TextSkeleton height={"6.5rem"} width={"20%"} />
            </Grid>
          </Grid>
        </MintHead>
      </SectionContainer>

      <SectionContainer pt={"5rem"}>
        <Grid container spacing={4.5}>
          <Grid item xs={12} md={5.7}>
            {" "}
            <MintBodyCard
              sx={{
                backgroundColor: "#f2e8e8",
                display: {xs: 'none', md: "block"},
                padding: "5rem",
                height: '50rem'
              }}
            >
              <RectSkeleton
                height={"100%"}
              />
            </MintBodyCard>
          </Grid>
          <Grid item xs={12} md={6.3}>
            {" "}
            <MintBodyCard
              sx={{ backgroundColor: "#f2e8e8", display: "block", p: "5rem", height: '50rem' }}
            >
              <Grid container spacing={"4rem"}>
                <Grid item xs={6}>
                  <RectSkeleton
                    height={"18rem"}
                  />
                </Grid>{" "}
                <Grid item xs={6}>
                  <RectSkeleton
                    height={"18rem"}
                  />
                </Grid>{" "}
                <Grid item xs={6}>
                  <RectSkeleton
                    height={"18rem"}
                  />
                </Grid>
                <Grid item xs={6} mt={'-1.5rem'}>
                  <TextSkeleton height={"6.8rem"} />
                  <TextSkeleton height={"6.8rem"} />
                  <TextSkeleton height={"6.8rem"} />
                </Grid>
                <Grid item xs={12} mt={'-1.5rem'}>
                  <TextSkeleton height={"6.8rem"} />
                </Grid>
              </Grid>
            </MintBodyCard>{" "}
          </Grid>
        </Grid>
      </SectionContainer>
    </>
  );
};

export default SkeletonPage;
